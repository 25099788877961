@import './app/pages/Applications.scss';

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

:root {
  /* Colors: */
  --bogey: #126e51;
  --darkGreen: #07553c;
  --pink: #e2009f;
  --purple: #965a92;
  --unnamed-color-a2dbca: #a2dbca;
  --unnamed-color-5c5c5c: #5c5c5c;
  --unnamed-color-12beb4: #12beb4;
  --white: #ffffff;
  --unnamed-color-b2b2b2: #b2b2b2;
  --green: #2ec193;
  --unnamed-color-282828: #282828;
  --unnamed-color-171717: #171717;
  --pagetitlebg: #f5f5f5;
  --system-ui: system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --poppins-reg: 'Poppins', var(--system-ui);
}

body {
  margin: 0;
  height: 100vh;
  height: 100svh;
  font-family: var(--poppins-reg);
  background-color: #f7f7f7;
}

h2, h3, h4 {
  margin: 0;
  font-family: var(--poppins-reg)!important;
  font-weight: 600;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

* {
  box-sizing: border-box;
}

//style admin grid header and pagination
.admin-grid-header,
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
{
  background-color: rgba(0, 0, 0, 0.06);
}
