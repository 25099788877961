// Firefox
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: #e0e0e0 transparent;
  }
}

// Chrome, Edge and Safari
::-webkit-scrollbar {
  width: 8px;
  height:  8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e0e0e0;
  transition: background-color 0.25s ease;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background: transparent;
}
