@import 'scrollbar';

.App {
  display: flex;
  flex-direction: column;
  font-family: var(--poppins-reg);
}

.applications {
  display: flex;
  height: 100%;
  font-family: var(--poppins-reg);
  background-color: #f7f7f7;

  &__employeeLogoContainer {
    display: none;
  }

  &__skeleton {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #f7f7f7;

    .MuiSkeleton-root {
      height: 100%;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  div.applications__LeftContainer {
    width: min(48%, 895px);
    padding: 0;
    min-width: 48%;
    position: relative;
  }

  &__aside {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-image: url('../assets/Myapps.png');
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
    padding-left: 40px;

    &-gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to right,
        var(--green) 0%,
        var(--bogey) 10%,
        var(--purple) 25%,
        var(--pink) 65%
      );
      clip-path: polygon(0 0, 100% 10%, 100% 60%, 0 100%);
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 704px;
      height: 100%;
      justify-content: flex-end;
    }

    &-logoContainer {
      position: relative;
      display: flex;
      margin-block: auto;
      width: fit-content;
    }

    &-exployeePortalLogo {
      position: absolute;
      width: 100%;
      height: fit-content;
      bottom: 100%;
      left: 0;
      padding-inline: 1.5%;
    }

    .applications__aside-title {
      color: white;
      font-size: 2.875rem;
      font-family: var(--poppins-reg);
      padding-top: 16px;

      &--highlight {
        color: #2cfcbd;
        font-weight: bold;
      }
    }

    &-bet365logo {
      margin-bottom: 64px;
    }
  }

  &__content {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__container {
    overflow-y: auto;
    padding: 40px;
    max-width: 832px;
    display: flex;
    flex: 1;
    gap: 24px;
    flex-direction: column;
  }

  &__card {
    cursor: pointer;
    background-color: white;
    border-radius: 16px;
    display: flex;
    padding: 16px;
    gap: 16px;
    border: 1px solid #f0f0f0;
    transition: 0.4s ease;

    &:hover {
      box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05);
      transform: translateY(-5px);
    }

    &-info {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &-icon {
      width: 60px;
      height: 60px;
    }

    &-content {
      flex-grow: 1;
      font-family: var(--poppins-reg);
      display: flex;
      align-items: center;
      overflow: hidden;

      .applications__card-contentFont {
        font-family: var(--poppins-reg);
        font-size: 1.125rem;
        letter-spacing: -0.1px;
        overflow: hidden;
        text-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }

    &-action {
      color: #2ec193 !important;
    }
  }

  &__footer {
    width: 100%;
    height: 141px;
    background-color: white;
    display: flex;
    flex-direction: column;

    &-gradientContainer {
      width: 100%;
      background-color: #c6349a;
      height: 10px;
    }

    &-gradient {
      height: 100%;
      width: 100%;
      max-width: 832px;
      background-image: linear-gradient(to right, #39a583, #c6349a);
    }

    &-logos {
      display: flex;
      justify-content: center;
      margin-block: auto;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      flex: 1;
      max-width: 832px;

      &-portal {
        height: 9px;
      }
    }
  }

  &__logout {
    padding: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 12px;
    max-width: 832px;

    .applications__logout-button {
      font-family: var(--poppins-reg);
      border-radius: 16px;
      color: #2ec193;
      text-transform: none;
      gap: 8px;
      padding: 4px 16px;
      font-weight: 400;
    }
  }

  &__drawer {
    & .MuiDrawer-paper {
      border-radius: 16px 0px 0px 16px;
      width: 700px;
    }

    &-content {
      width: auto;
      padding: 20px;

      &-header {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }

      &-icon {
        width: 60px;
        height: 60px;
        margin-right: 16px;
      }

      &-close {
        color: #2ec193 !important;
      }

      &-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-launch {
        border-radius: 8px;
        background-color: #2ec193 !important;
        color: var(--white);
      }
    }
  }
}

/** tablet portrait */
@media (max-width: 1366px) and (orientation: portrait) {
  .applications {
    flex-direction: column;
    display: flex;
    overflow-y: auto;
    max-height: 100vh;

    &__employeeLogoContainer {
      display: flex;
      min-height: 64px;
      align-items: flex-end;
      justify-content: center;
    }

    &__employeeLogo {
      height: clamp(0.8125rem, 0.5599rem + 0.8083vi, 1.25rem);
      width: 100%;
    }

    div.applications__LeftContainer {
      width: 100%;
      padding: 0;
      position: relative;
    }

    &__skeleton {
      height: 36vh;
    }

    &__aside {
      height: 36vh;
      clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
      background-position: center;
      padding-left: 0;

      &-exployeePortalLogo {
        display: none;
      }

      &-content {
        width: 100%;
        max-width: unset;
        align-items: center;
        font-size: 3.25rem;
      }

      &-gradient {
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          to right,
          var(--green) 0%,
          var(--bogey) 15%,
          var(--purple) 35%,
          var(--pink) 70%
        );
        clip-path: polygon(0 0%, 100% 20%, 0 100%);
        z-index: 0;
      }

      .applications__aside-logoContainer {
        height: 60%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-shadow: 2px 1px 25px black;
      }

      .applications__aside-title {
        font-size: clamp(2rem, 1.2783rem + 2.3095vi, 3.25rem);
        text-shadow: #000 1px 0 10px;
        padding-bottom: 3%;
      }

      &-bet365logo {
        display: none;
      }
    }

    &__content {
      align-items: center;
      flex: 1;
    }

    &__container {
      width: 100%;
    }

    &__footer {
      align-items: center;

      &-gradient {
        max-width: unset;
      }
    }

    &__logout {
      background-color: #f7f7f7;
      position: sticky;
      bottom: 0;
    }
  }
}

/** Mobile landscape */
@media (min-aspect-ratio: 1/1) and (max-height: 500px) {
  .applications {
    display: flex;
    height: 100%;

    &__aside {
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: 68%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);

      .applications__aside-title {
        font-size: 1.875rem;
        padding-top: 8px;
      }

      &-gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to right,
          var(--green) 0%,
          var(--bogey) 10%,
          var(--purple) 25%,
          var(--pink) 65%
        );
        clip-path: polygon(0 0, 100% 15%, 100% 60%, 0 100%);
      }
    }

    &__footer {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .applications {
    flex-direction: column;
    display: flex;
    overflow-y: auto;
    max-height: 100vh;

    &__employeeLogoContainer {
      align-items: center;
    }

    &__employeeLogo {
      height: 13px;
      width: 100%;
    }

    div.applications__LeftContainer {
      width: 100%;
      padding: 0;
      position: relative;
    }

    &__aside {
      &-exployeePortalLogo {
        display: none;
      }

      .applications__aside-title {
        font-size: 1.8125rem;
        text-shadow: #000 1px 0 10px;
      }

      &-bet365logo {
        display: none;
      }
    }

    &__footer {
      height: 227px;

      &-gradientContainer {
        display: none;
      }

      &-logos {
        gap: 24px;
        padding-top: 67px;
        justify-content: flex-start;

        &-bet365 {
          width: 100%;
          height: 23px;
        }
        &-portal {
          width: 100%;
          height: 13px;
        }

        &-triangles {
          width: 100%;
          height: 70px;
          background-image: url('../assets/Triangles.svg');
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

